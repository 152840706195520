import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import server from '../api/server';
import history from '../history';
import AdminContainer from './AdminContainer';

export class SaleAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sales: [],
            editingSale: null,
            modalData: {
                coupon: "",
                banner_image: "",
                banner_title: "",
                banner_description: "",
                percent: 0,
                expires_at: this.getDefaultExpiryDate(),
                active: false,
                sale_items: {
                    monthly: false,
                    yearly: false,
                    lifetime: false,
                    monthly_unlimited: false,
                    yearly_unlimited: false,
                    lifetime_unlimited: false
                }
            },
            disabled: false
        };
    }

    componentDidMount() {
        if (this.props.user.admin_perms.coupons != true) {
            history.push('/');
        }
        this.getSales();
    }

    getSales = async () => {
        const response = await server.get("/admin/sales");
        if (response.data && response.data.success) {
            this.setState({ sales: response.data.sales });
        }
    };

    getDefaultExpiryDate = () => {
        const date = new Date();
        date.setDate(date.getDate() + 7); // Default to 7 days from now
        return date.toISOString().slice(0, 16); // Format for datetime-local input
    };

    formatDateForDisplay = (date) => {
        return moment(date).format('MMMM Do YYYY, h:mm a');
    };

    handleModalInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (name.startsWith('sale_items.')) {
            const itemName = name.split('.')[1];
            this.setState(prevState => ({
                modalData: {
                    ...prevState.modalData,
                    sale_items: {
                        ...prevState.modalData.sale_items,
                        [itemName]: checked
                    }
                }
            }));
        } else if (type === 'checkbox') {
            this.setState(prevState => ({
                modalData: {
                    ...prevState.modalData,
                    [name]: checked
                }
            }));
        } else {
            this.setState(prevState => ({
                modalData: {
                    ...prevState.modalData,
                    [name]: value
                }
            }));
        }
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ disabled: true });

        const response = await server.post("/admin/sales", {
            ...this.state.modalData,
            id: this.state.editingSale?._id
        });

        if (response.data && response.data.success) {
            await this.getSales();
            window.$('#saleModal').modal('hide');
            this.setState({
                modalData: {
                    coupon: "",
                    banner_image: "",
                    banner_title: "",
                    banner_description: "",
                    percent: 0,
                    expires_at: this.getDefaultExpiryDate(),
                    active: false,
                    sale_items: {
                        monthly: false,
                        yearly: false,
                        lifetime: false,
                        monthly_unlimited: false,
                        yearly_unlimited: false,
                        lifetime_unlimited: false
                    }
                },
                editingSale: null,
                disabled: false
            });
        }
    };

    toggleActive = async (sale) => {
        if (!sale.active && this.state.sales.some(s => s.active && s._id !== sale._id)) {
            if (!window.confirm('Another sale is currently active. Activating this sale will deactivate the other sale. Continue?')) {
                return;
            }
        }

        const response = await server.post("/admin/sales", {
            ...sale,
            active: !sale.active
        });

        if (response.data && response.data.success) {
            await this.getSales();
        }
    };

    editSale = (sale) => {
        this.setState({
            editingSale: sale,
            modalData: {
                coupon: sale.coupon,
                banner_image: sale.banner_image,
                banner_title: sale.banner_title,
                banner_description: sale.banner_description,
                percent: sale.percent || 0,
                expires_at: moment(sale.expires_at).format('YYYY-MM-DDTHH:mm'),
                active: sale.active,
                sale_items: sale.sale_items
            }
        });
        window.$('#saleModal').modal('show');
    };

    deleteSale = async (id) => {
        if (window.confirm('Are you sure you want to delete this sale?')) {
            const response = await server.delete(`/admin/sales/${id}`);
            if (response.data && response.data.success) {
                await this.getSales();
            }
        }
    };

    renderSales = () => {
        return this.state.sales.map(sale => (
            <tr key={sale._id} className={sale.active ? 'table-success' : ''}>
                <td>{sale.coupon}</td>
                <td>{sale.banner_title}</td>
                <td>
                    {Object.entries(sale.sale_items)
                        .filter(([_, value]) => value)
                        .map(([key]) => key.replace('_', ' '))
                        .join(', ')}
                </td>
                <td>{sale.percent}% off</td>
                <td>{this.formatDateForDisplay(sale.expires_at)}</td>
                <td>{moment(sale.created_at).format('MMMM Do YYYY')}</td>
                <td>
                    <button
                        className={`btn btn-sm me-2 ${sale.active ? 'btn-warning' : 'btn-success'}`}
                        onClick={() => this.toggleActive(sale)}
                    >
                        {sale.active ? 'Deactivate' : 'Activate'}
                    </button>
                    <button className="btn btn-primary btn-sm me-2" onClick={() => this.editSale(sale)}>
                        Edit
                    </button>
                    <button className="btn btn-danger btn-sm" onClick={() => this.deleteSale(sale._id)}>
                        Delete
                    </button>
                </td>
            </tr>
        ));
    };

    render() {
        return (
            <AdminContainer title="Sales Management">
                <div id="saleModal" className="modal fade" tabIndex="-1" aria-labelledby="saleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="saleModalLabel">
                                    {this.state.editingSale ? 'Edit Sale' : 'New Sale'}
                                </h5>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <form onSubmit={this.handleSubmit}>
                                <div className="modal-body">
                                    <div className="mb-3">
                                        <div className="form-check form-switch">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="active"
                                                checked={this.state.modalData.active}
                                                onChange={this.handleModalInputChange}
                                                id="activeSaleSwitch"
                                            />
                                            <label className="form-check-label" htmlFor="activeSaleSwitch">
                                                Active Sale
                                            </label>
                                            {this.state.sales.some(s => s.active && s._id !== this.state.editingSale?._id) && (
                                                <div className="form-text text-warning">
                                                    Note: Activating this sale will deactivate the currently active sale
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Coupon Code</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="coupon"
                                            value={this.state.modalData.coupon}
                                            onChange={this.handleModalInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Discount Percentage</label>
                                        <input
                                            type="number"
                                            className="form-control"
                                            name="percent"
                                            value={this.state.modalData.percent}
                                            onChange={this.handleModalInputChange}
                                            min="0"
                                            max="100"
                                            required
                                        />
                                        <div className="form-text">
                                            Enter a number between 0 and 100 (e.g. 90 for 90% off)
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Banner Image URL</label>
                                        <input
                                            type="url"
                                            className="form-control"
                                            name="banner_image"
                                            value={this.state.modalData.banner_image}
                                            onChange={this.handleModalInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Banner Title</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="banner_title"
                                            value={this.state.modalData.banner_title}
                                            onChange={this.handleModalInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Banner Description</label>
                                        <textarea
                                            className="form-control"
                                            name="banner_description"
                                            value={this.state.modalData.banner_description}
                                            onChange={this.handleModalInputChange}
                                            required
                                        />
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Expires At</label>
                                        <input
                                            type="datetime-local"
                                            className="form-control"
                                            name="expires_at"
                                            value={this.state.modalData.expires_at}
                                            onChange={this.handleModalInputChange}
                                            required
                                        />
                                        <div className="form-text">
                                            Select when this sale should expire
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <label className="form-label">Sale Items</label>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.monthly"
                                                checked={this.state.modalData.sale_items.monthly}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Monthly</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.yearly"
                                                checked={this.state.modalData.sale_items.yearly}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Yearly</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.lifetime"
                                                checked={this.state.modalData.sale_items.lifetime}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Lifetime</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.monthly_unlimited"
                                                checked={this.state.modalData.sale_items.monthly_unlimited}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Monthly Unlimited</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.yearly_unlimited"
                                                checked={this.state.modalData.sale_items.yearly_unlimited}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Yearly Unlimited</label>
                                        </div>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                name="sale_items.lifetime_unlimited"
                                                checked={this.state.modalData.sale_items.lifetime_unlimited}
                                                onChange={this.handleModalInputChange}
                                            />
                                            <label className="form-check-label">Lifetime Unlimited</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                        Close
                                    </button>
                                    <button type="submit" className="btn btn-primary" disabled={this.state.disabled}>
                                        {this.state.editingSale ? 'Update Sale' : 'Create Sale'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12 col-md-12">
                        <div className="justify-content-between d-flex align-items-center mt-3 mb-4">
                            <h5 className="mb-0 pb-1">Sales Management</h5>
                            <button
                                className="btn btn-success"
                                onClick={() => {
                                    this.setState({
                                        editingSale: null,
                                        modalData: {
                                            coupon: "",
                                            banner_image: "",
                                            banner_title: "",
                                            banner_description: "",
                                            percent: 0,
                                            expires_at: this.getDefaultExpiryDate(),
                                            active: false,
                                            sale_items: {
                                                monthly: false,
                                                yearly: false,
                                                lifetime: false,
                                                monthly_unlimited: false,
                                                yearly_unlimited: false,
                                                lifetime_unlimited: false
                                            }
                                        }
                                    });
                                    window.$('#saleModal').modal('show');
                                }}
                            >
                                New Sale
                            </button>
                        </div>
                        <table className="table table-dark table-striped table-wrap">
                            <thead>
                                <tr>
                                    <th>Coupon</th>
                                    <th>Title</th>
                                    <th>Items</th>
                                    <th>Discount</th>
                                    <th>Expires</th>
                                    <th>Created</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>{this.renderSales()}</tbody>
                        </table>
                    </div>
                </div>
            </AdminContainer>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user
});

export default connect(mapStateToProps)(SaleAdmin);