import React, { Component } from 'react';
import { connect } from 'react-redux';
import AdminContainer from "./AdminContainer";
import Search from './Search';
import Home from './Home';
import { Switch, Route, } from 'react-router-dom';
import Requests from './Requests';
import Coupons from './Coupons';
import Partnerships from './Partnerships';
import SlashErrors from './SlashErrors';
import Triggers from './Triggers';
import AITriggers from './AITriggers';
import Codes from './Codes';
import Modules from './Modules';
import SeoModulePages from './SeoModulePages';
import PrioHosting from './PrioHosting';
import SaleAdmin from './SaleAdmin';
export class App extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/search" component={Search}></Route>
        <Route exact path="/requests" component={Requests}></Route>
        <Route exact path="/coupons" component={Coupons}></Route>
        <Route exact path="/partnerships" component={Partnerships}></Route>
        <Route exact path="/slasherrors" component={SlashErrors}></Route>
        <Route exact path="/triggers" component={Triggers}></Route>
        <Route exact path="/sales" component={SaleAdmin}></Route>
        <Route exact path="/aisupport" component={AITriggers}></Route>
        <Route exact path="/codes" component={Codes}></Route>
        <Route exact path="/modules" component={Modules}></Route>
        <Route exact path="/prio-hosting" component={PrioHosting}></Route>
        <Route exact path="/seo-module-pages" component={SeoModulePages}></Route>
        <Route exact path="/" component={Home}></Route>
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(App);